.input{
    border: none;
    border-bottom: 1px solid silver;
}
.input:focus {
    outline: none;
  }

.label{
    display: inline-block;
    transform-origin: center left !important;
}

.grid{
    display: flex;
    align-items: flex-start;
}