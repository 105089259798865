.error{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    width: 100vw;
    background-color: #f5f5f5;
    color: #333;
    text-align: center;
    h1{
        font-size: 5rem;
        margin: 0;
    }
    h2{
        font-size: 2rem;
        margin: 0;
    }
    p{
        font-size: 1.5rem;
        margin: 0;
    }
    a{
        font-size: 1.5rem;
        margin: 0;
        color: #333;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }

    .message{
        color: purple;
        margin-top: 30px;
    }
}
