.window{
    display: flex;
    flex-direction: column;
    text-align: center;
    min-height: 100vh;
    margin: 0 auto;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 28rem;
}

.logo{
    max-width: 140px;
    margin: 0 auto;
}

.form{
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;

}

.formControlGroup{

}