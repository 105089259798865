
.modal {
    display: flex;
    align-items: center;
    
}

.modalContent {
    background-color: black;
    border: 2px solid #000;
    border-radius: 4px;
    padding: 4;
    text-align: 'center';
  }