.navigation
{
    a{
        text-decoration: none;
    }

    .active{
        font-weight: bold;
        text-decoration: underline;
    }
}