html,body,#root{
    min-height: 100%;
}
body {
  margin: 0;
  font-family: Quicksand, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root{
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.print-only{
  display: none;
}


h1{
    font-weight: 500;
    font-size: 3rem;
    line-height: 1.167;
/* 
    @media (min-width: 600px){
          font-size: 4.7129rem;
    }
    @media (min-width: 900px){
          font-size: 5.3556rem;
    }
    @media (min-width: 1200px){
          font-size: 3.5rem;
    } */
}

tr.MuiTableRow-root.MuiTableRow-head {
  background: #fdd8df;
}
.MuiTableFooter-root td.MuiTableCell-root.MuiTableCell-sizeSmall {
  background: #e2e2e2;
}


@media print {

  .print-only{
    display: block !important;
  }
  /* .no-print {
    display: none !important;
  }

  body * {
    visibility: hidden;
  }
  .print, .print * {
    visibility: visible;
  }
  .print {
    position: absolute;
    left: 0;
    top: 0;
    
    
    width: 100%;
  } */

}